<template>
    <v-card borderless tile class="grey lighten-5">
        <v-card-title class="primary--text text-h5 text-md-h4 flex-nowrap align-start">
            <v-row class="align-center">
                <v-col cols="auto">
                    {{ parentSelectedRow.anzBelege }}
                    {{ parentSelectedRow.anzBelege === 1 ? 'Position' : 'Positionen' }}
                    im Einspruch {{ parentSelectedRow.einspruch.einspruchNr }}
                </v-col>
                <v-col cols="auto" class="flex-grow-1">
                    <v-alert :value="!parentSelectedRow.einspruch.einspruchNr"
                             type="warning" border="left" class="mb-0" dense outlined>

                        <!-- Default template hat kein vertikal zentriertes icon? -->
                        <template v-slot:prepend>
                            <v-icon class="mr-2" color="warning">$vuetify.icons.warning</v-icon>
                        </template>

                        Dieser Einspruch wurde noch nicht verarbeitet und hat noch keine Einspruchsnummer
                    </v-alert>
                </v-col>
            </v-row>
        </v-card-title>
        <v-card-text class="text-body-1 text--primary">
            <v-data-table :headers="headers" :items="einspruchPos" item-key="belegnr"
                          :expanded.sync="expanded" :dense="$vuetify.breakpoint.mdAndDown"
                          :items-per-page="itemsPerPage" :hide-default-footer="einspruchPos.length <= itemsPerPage"
                          class="font-weight-semibold text-no-wrap text-body-2 text-lg-body-1
                            scroll-gradient elevation-2 rounded-0 alternating-row-colors" single-expand>
                <template v-slot:item.belegnr="{ item }">
                    <span>{{ item.belegnr }}</span>
                    <v-tooltip v-if="item.eRezept" bottom>
                        <template v-slot:activator="{ on }">
                            <v-icon v-on="on" size="small" class="ml-2">
                                mdi-alpha-e-circle
                            </v-icon>
                        </template>
                        <span>E-Rezept-ID: {{ item.eRezept }}</span>
                    </v-tooltip>
                </template>

                <template v-slot:item.status="{ item }">
                    <v-chip :color="getColor(item.status)"
                            :small="$vuetify.breakpoint.mdAndDown">

                        {{ item.status }}
                    </v-chip>
                </template>

                <template v-slot:item.anerkannt="{ item }">
                    <span :class="getColorAnerkannt(item.betrag, item.anerkannt)">
                        {{ item.anerkannt }}
                    </span>
                </template>

                <template v-slot:item.image="{ item }">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on: tooltip }">
                            <v-btn v-on="{ ...tooltip }" @click="selectAndToggleRow(item)"
                                   :small="$vuetify.breakpoint.mdAndDown" icon>

                                <v-icon color="primary">
                                    $vuetify.icons.formatList
                                </v-icon>
                            </v-btn>
                        </template>
                        <span>Bitte anklicken, um das Rezept-Image zu sichten</span>
                    </v-tooltip>
                </template>

                <template v-slot:item.download="{ item }">
                    <div v-if="item.begruendungen.anzahlDoc>0">
                        <v-menu offset-y bottom left>
                            <template v-slot:activator="{ on: menu, attrs }">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on: tooltip }">
                                        <v-btn v-bind="attrs" v-on="{ ...tooltip, ...menu }"
                                               @click.stop.prevent="getDokumentListe(item)"
                                               :small="$vuetify.breakpoint.mdAndDown" text>

                                            <v-icon color="primary" :small="$vuetify.breakpoint.mdAndDown">
                                                $vuetify.icons.fileDownload
                                            </v-icon>
                                            <span class="font-weight-semibold text-body-2 text-lg-body-1">
                                                ({{ item.begruendungen.anzahlDoc }})
                                            </span>
                                        </v-btn>
                                    </template>
                                    <span v-if="item.begruendungen.anzahlDoc==1">Bitte anklicken, um das Dokument zu sichten</span>
                                    <span v-else-if="item.begruendungen.anzahlDoc > 1">Bitte anklicken, um die Dokumente zu sichten</span>
                                </v-tooltip>
                            </template>
                            <v-list dense>
                                <v-list-item v-for="(item, index) in dokumente" :key="index" @click="downloadfile(item)">
                                    <v-list-item-action>
                                        <v-list-item-title class="text-body-1">
                                            {{ item.originalName }}
                                        </v-list-item-title>
                                    </v-list-item-action>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </div>
                </template>

                <template v-slot:expanded-item="{ headers, item }">
                    <td v-bind:colspan="headers.length" class="pa-0">
                        <v-container fluid class="pa-4">
                            <v-row class="align-center" dense>
                                <v-col cols="12" sm="6" lg="5">

                                    <!--
                            Achtung: Kein v-img, denn dieses verändert ungewollt die
                            Dimensionen des/der parent container (Bug in Vuetify?)
                        -->
                                    <img :src="'data:image/png;base64,'+ item.image" class="tableImg" />
                                </v-col>
                                <v-col cols="12" sm="6" lg="7">
                                    <v-card borderless>
                                        <v-card-title class="text-h5">
                                            Retax-Begründung
                                        </v-card-title>
                                        <v-card-text class="text-body-1 text--primary font-weight-semibold">
                                            {{ item.begruendungen.grundlang}}
                                        </v-card-text>
                                    </v-card>
                                    <v-card v-show="item.begruendungen.einspruchBegruendung" borderless>
                                        <v-card-title class="text-h5">
                                            Einspruchsbegründung
                                        </v-card-title>
                                        <v-card-text class="text-body-1 text--primary font-weight-semibold">
                                            {{ item.begruendungen.einspruchBegruendung}}
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-container>
                    </td>
                </template>
            </v-data-table>
        </v-card-text>
    </v-card>
</template>

<script>
    import axios from 'axios'
    import eventBus from '@/main'

    export default {

        name: "TableEinspruchPositionen",

        data: () => ({
            selectedRow: null,
            expanded: [],
            headers: [

                { text: 'Beleg-Nr', value: 'belegnr' },
                { text: 'Nachname', value: 'nachname' },
                { text: 'Status', value: 'status' },
                { text: 'Grund', value: 'grund' },
                { text: 'über (€)', value: 'betrag', align: 'end' },
                { text: 'anerkannt (€)', value: 'anerkannt', align: 'end' },
                { text: '', value: 'image', align: 'end' },
                { text: '', value: 'download', align: 'end' },
            ],

            einspruchPos: [],
            dokumente: [],
            tmpEinspruchsliste: [],
            selectedBelegNr: null,
            itemsPerPage: 5,
        }),

        props: ['parentSelectedRow', 'parentBelegNr'],

        mounted() {
            var selRow = { row: this.parentSelectedRow, belegNr: this.parentBelegNr };
            if (selRow !== null) {
                this.getEinspruchPos(selRow.row.einspruch.einspruchID, selRow.row.einspruch.aspEinspruchID, selRow.belegNr);
            }
        },

        watch: {
            '$attrs.id'() {
                this.getEinspuch(this.$attrs.id);
            },

            'parentSelectedRow'() {
                var selRow = { row: this.parentSelectedRow, belegNr: this.parentBelegNr };
                if (selRow !== null) {
                    this.getEinspruchPos(selRow.row.einspruch.einspruchID, selRow.row.einspruch.aspEinspruchID, selRow.belegNr);
                }
            }
        },

        methods: {

            selectAndToggleRow(row) {
                this.selectedRow = row;
                this.expanded = this.expanded.includes(row) ? [] : [row];

                //console.log('this.expanded :', this.expanded, 'this.expanded.length', this.expanded.length);
                const le = this.$store.state.currentUser.leistungserbringer;

                if (this.expanded.length > 0) {
                  //  await
                    this.$http.get('retax/belegdetails', {
                        params: {
                            Leistungserbringer: le, //this.$store.state.currentUser.leistungserbringer,
                            BM: this.parentSelectedRow.bm,
                            BelegNr: this.selectedRow.belegnr  //ep.belegNr,
                        }
                    }).then((response) => {
                        if (response.status === 200) {
                            var resultpos = response.data;

                            this.selectedRow.begruendungen.grundlang = resultpos.belegKorrekturGrund[0].korrekturGrund;
                            this.selectedRow.image = resultpos.belegImageByte;

                           /* var index = this.einspruchPos.findIndex(x => x.belegnr === this.selectedRow.belegnr || x.eRezept === this.selectedRow.eRezept);
                            console.log('index ', index, 'this.einspruchPos[index]: ', this.einspruchPos[index]);

                            if (index != -1) {
                                this.einspruchPos[index].begruendungen.grundlang = resultpos.belegKorrekturGrund[0].korrekturGrund;
                                this.einspruchPos[index].image = resultpos.belegImageByte;
                                console.log('resultpos.belegImageByte: ', resultpos.belegImageByte)
                            }
                            */
                        }
                    }).catch(error => {
                        if (error.response) {
                            this.$store.commit('updateCurrentError', error.response.data);
                        }
                    });
                }

            },

            downloadfile(item) {
                belegNr = belegNr
                const dateiName = item.originalName;
                let aspEinpruchId = this.parentSelectedRow.einspruch.aspEinspruchID;
                let belegNr = this.selectedBelegNr !== null ? this.selectedBelegNr : "belegNr";;
                //const fileExtention = dateiName.substring(dateiName.length - 4, dateiName.length);
                /*const url = 'einspruch/downloadfile' + item.pfad.slice(1) + item.dateiName + fileExtention;*/
                //"0/" enspricht die Dokumentart = 0, d.h. Benutzerdoument und kein Enstscheidungsdokument von der GFS
                const url = 'einspruch/downloadfile/' + aspEinpruchId + "/" + "0/" + item.dateiName + "/" + belegNr;

                axios({
                    method: 'get',
                    url: url, //'einspruch/downloadfile' + item.pfad + item.dateiName + fileExtention , // /2005/5f8cdbbf23595b85a2af1c71e6dd3196.png',
                    responseType: 'arraybuffer',
                })
                    .then((response) => {
                        if (response.status === 200) {
                            const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/octet-stream' }))
                            const link = document.createElement('a')
                            link.href = url
                            link.setAttribute('download', dateiName)
                            document.body.appendChild(link)
                            link.click()
                        }
                        /*this.forceFileDownload(response, dateiName)*/
                    })
                    .catch( error => console.log('Fehler beim Downloaden: ', error.response))
            },

            getEinspruchPos: function (einspruchId, aspEinspruchId, belegNr) {
                const vm = this;
                const le = this.$store.state.currentUser.leistungserbringer;

                this.einspruchPos = [];
                this.selectedBelegNr = null;
                this.isBusy = true;

                this.$http.get('/einspruch/pos', {
                    params: {
                        Leistungserbringer: le,
                        EinspruchID: einspruchId,
                        ASPEinspruchID: aspEinspruchId,
                    }
                }).then((response) => {
                    //this.isBusy = false;
                    if (response.status === 200) {
                        var result = response.data;
                        this.tmpEinspruchsliste = result;

                        result.forEach(ep => {
                            let detailKorrGrund = null;
                            let img = null;
                                      vm.einspruchPos.push({
                                        belegnr: ep.belegNr, nachname: ep.nachname, status: ep.statusPosBezeichnung, grund: ep.retaxGrund,
                                        betrag: this.convertToEuro(ep.betrag), anerkannt: this.convertToEuro(ep.anerkannt), eRezept: ep.eRezept,
                                        image: img,
                                        begruendungen: {
                                            grundlang: '', //detailKorrGrund[0].korrekturGrund,
                                            einspruchBegruendung: ep.begruendungText,
                                            anzahlDoc: ep.anzahlDoc
                                        },
                                    })
                                    if (belegNr != null) {
                                        let idx = vm.einspruchPos.findIndex(x => x.belegnr == belegNr);
                                        if (idx !== -1 && idx !== 0) {
                                            vm.einspruchPos.unshift(vm.einspruchPos.splice(idx, 1)[0]);
                                        }
                                    }

                        });
                    }
                    this.isBusy = false;
                }).catch(error => {
                    if (error.response) {
                        this.snackbar = true;
                        this.isBusy = false;
                    }
                });
            },

            getDokumentListe(item) {
                this.isBusy = true;
                eventBus.$emit('resetLogoutTimer', {});
                this.dokumente = [];
                this.selectedBelegNr = item.belegnr;
                //let belegInfo = this.tmpEinspruchsliste.find(e => e.belegNr === item.belegnr);
                let aspEinpruchId = this.parentSelectedRow.einspruch.aspEinspruchID;

                this.$http.get('/einspruch/doc', {
                    params: {
                        ASP_ID: aspEinpruchId,
                        BelegNr: item.belegnr,
                    }
                }).then((response) => {
                    this.isBusy = false;
                    if (response.status === 200) {
                        if (response.data !== null) this.dokumente = response.data;
                        this.isBusy = false;
                    }
                }).catch(error => {
                    if (error.response) {
                        this.snackbar = true;
                        this.isBusy = false;
                    }
                });
            },

            getColor(status) {
                if (status === 'geschlossen') return 'grey lighten-2'
                else if (status === 'abgelehnt') return 'orange'
                else if (status === 'anerkannt') return 'lime '
                else return 'teal lighten-3'
            },
            getColorAnerkannt(summe, anerkannt) {
                let s = Math.abs(parseFloat(summe.replace(",", ".")).toFixed(2));
                let a = parseFloat(anerkannt.replace(",", "."));
                if (a === 0.00) {
                    return 'red--text'
                }
                else if (a < s) {
                    return 'orange--text'
                }
                else {
                    return 'green--text'
                }
            },
            convertToEuro(value) {
                return new Intl.NumberFormat('de-DE', { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(value / 100)
            }
        }
    }
</script>
<style scoped>

    .tableImg {
        width: 100%;
        border: solid thin black;

        /* Keine Ahnung, warum v-col 8px zu hoch ist */
        margin-bottom: -8px;
    }

</style>
