<template>
    <v-container fluid class="pa-0">
        <v-row class="justify-start">
            <v-col cols="12">

                <v-form ref="einspruecheForm" lazy-validation>
                    <v-card borderless>
                        <v-card-title class="text-h5 text-md-h4 flex-nowrap align-start">
                            Meine Einsprüche
                        </v-card-title>
                        <v-card-text class="text-body-1 text--primary">
                            <v-container fluid class="px-0 pb-0">
                                <v-row>
                                    <v-col cols="12" sm="6" lg="2">
                                        <v-select :label="selectedKasse === null || selectedKasse.length == 0 ? 'Alle Kostenträger' : 'Kostenträger'"
                                                  :items="kassen" item-text="bezeichnung" item-value="mandant" v-model="selectedKasse" 
                                                  :menu-props="{ maxHeight: '400', nudgeBottom: '30px' }" :disabled="disabled" 
                                                  class="custom-dense" clearable multiple />

                                    </v-col>
                                    <v-col cols="12" sm="6" lg="2">
                                        <v-select :label="selectedMonat === null || selectedMonat.length == 0? '' : 'Monat'" 
                                                  :items="bMonate" item-text="Bezeichnung" item-value="BMonat" v-model="selectedMonat"
                                                  :menu-props="{ maxHeight: '400', nudgeBottom: '30px' }" :disabled="disabled"
                                                  class="custom-dense"  />

                                    </v-col>
                                    <v-col cols="12" sm="6" lg="2">
                                        <v-select :label="selectedStatus === null || selectedStatus.length == 0 ? 'Alle Status' : 'Status'"
                                                  :items="status" item-text="Bezeichnung" item-value="Status" v-model="selectedStatus"
                                                  :menu-props="{ maxHeight: '400', nudgeBottom: '30px' }" :disabled="disabled"                                            
                                                  class="custom-dense" clearable multiple />

                                    </v-col>
                                    <v-col cols="12" sm="6" lg="2">
                                        <v-text-field label="Belegnummer" v-model="belegNr"
                                                      autocomplete="off" :rules="belegNrRule" 
                                                      dense clearable />

                                    </v-col>
                                    <v-col cols="auto">
                                        <v-btn color="primary" type="submit"
                                               @click.stop.prevent="getBelegEinpspruchListe">
                                    
                                            Suchen
                                        </v-btn>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" class="pr-6">
                                        <v-data-table :headers="headers" :items="showItems"
                                                      item-key="id" :items-per-page="10" :custom-sort="customSort"
                                                      :expanded.sync="expanded"
                                                      :dense="$vuetify.breakpoint.mdAndDown"
                                                      class="font-weight-semibold text-no-wrap text-body-2 text-lg-body-1
                                                        scroll-gradient elevation-2 rounded-0 alternating-row-colors"
                                                      single-select single-expand>

                                            <template v-slot:item.bm="{ item }">
                                                {{ String(item.bm).substring(2, String(item.bm).length) + "/20" + String(item.bm).substring(0, String(item.bm).length - 2) }}
                                            </template>

                                            <template v-slot:item.anzBelege="{ item }">
                                                <v-tooltip bottom>
                                                    <template v-slot:activator="{ on: tooltip }">
                                                        <v-btn color="primary" v-show="item.anzBelege" v-on="{ ...tooltip }"
                                                               @click="selectAndToggleRow(item)"
                                                               :small="$vuetify.breakpoint.mdAndDown" depressed>

                                                            {{ item.anzBelege }}
                                                        </v-btn>
                                                    </template>
                                                    <span>Bitte anklicken, um die Einspruchspositionen zu öffnen</span>
                                                </v-tooltip>
                                            </template>

                                            <template v-slot:item.status="{ item }">
                                                <v-chip :color="getColor(item.status)"
                                                        :small="$vuetify.breakpoint.mdAndDown">

                                                    {{ statusBezeichnung(item.status) }}
                                                </v-chip>
                                            </template>

                                            <template v-slot:item.betragAnerkannt="{ item }">
                                                <span :class="getColorAnerkannt(item.betragSumme, item.betragAnerkannt)">
                                                    {{ item.betragAnerkannt }}
                                                </span>
                                            </template>

                                            <template v-slot:item.eingangsdatum="{ item }">
                                                {{ item.eingangsdatum ? moment(item.eingangsdatum).format('DD.MM.YYYY') : null }}
                                            </template>
                                            
                                            <template v-slot:item.entscheidung="{ item }">
                                                <v-tooltip v-if="item.entscheidung.Datum && item.anzahlDoc.entscheidungDoc > 0" bottom>
                                                    <template v-slot:activator="{ on: tooltip }">
                                                        <v-btn v-on="{ tooltip }" @click="downloadfile(item, 1)"
                                                                :small="$vuetify.breakpoint.mdAndDown" text>

                                                            <!--Anhänge nur an den Positionen-->
                                                            <v-icon color="primary" class="mr-1">
                                                                mdi-download
                                                            </v-icon>
                                                            <span class="font-weight-semibold text-body-2 text-lg-body-1">
                                                                {{ item.entscheidung.Datum ? moment(item.entscheidung.Datum).format('DD.MM.YYYY') : null }}
                                                            </span>
                                                        </v-btn>
                                                    </template>
                                                    <span>Entscheidungsdokument herunterladen</span>
                                                </v-tooltip>
                                            </template>
                                            
                                            <template v-slot:expanded-item="{ headers, item }">
                                                <td v-bind:colspan="headers.length" class="pa-0">
                                                    <table-einspruch-positionen :parentSelectedRow="selectedRow" :parentBelegNr="belegNr" />
                                                </td>
                                            </template>
                                        </v-data-table>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                    </v-card>
                </v-form>

            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import TableEinspruchPositionen from '@/components/TableEinspruchPositionen.vue'
    import eventBus from '@/main';
    import axios from 'axios'

    export default {
        components: {
            TableEinspruchPositionen
        },
        data: () => ({
            items: [],
            isBusy: false,
            selectedRow: null,
            expanded: [],
            //kassen: null,
            selectedKasse: null,
            bMonate: [],
            selectedMonat: null,
            status: null,
            selectedStatus: null,
            headers: [
                {
                    text: '#',
                    align: 'start',
                    sortable: false,
                    value: 'id'
                },
                {
                    text: 'Einspruch-Nr',
                    align: 'start',
                    value: 'einspruch.einspruchNr'
                },
                { text: 'Kostenträger', value: 'mandantBezeichung' },
                { text: 'Monat', value: 'bm' },
                { text: '# Belege', value: 'anzBelege' },
                { text: 'Status', value: 'status' },
                { text: 'über (€)', value: 'betragSumme', align: 'end' },
                { text: 'anerkannt (€)', value: 'betragAnerkannt', align: 'end' },
                { text: 'eingegangen', value: 'eingangsdatum', align: 'end' },
                { text: 'entschieden', value: 'entscheidung', align: 'center' },
            ],
            einsprueche: [],
            dokumente: [],
            selectedEinspruch: null,
            needsReload: false,
            belegNr: null,
            belegNrRule: [
                v => {
                    if (v) return (/^[0-9]\d*$|^$/.test(v) && v.length == 18) || 'Belegnummer muss genau aus 18 Ziffern bestehen!';
                    else return true;
                },
            ],


        }),
        computed: {
            indexedItems() {
                return this.einsprueche.map((item, index) => ({
                    id: index + 1,
                    ...item,
                }))
            },
            showItems() {
                return this.indexedItems.map(item => {
                    let filtered = Object.assign({}, item)
                    this.headers.forEach(header => {
                        if (header.text === 'aspEinspruchID') {
                            delete filtered[header.value]
                        }
                    })
                    return filtered
                })
            },
            disabled() {
                return this.belegNr !== null && this.belegNr !== "";
            },
            currentLE() {
                return this.$store.state.currentUser?.leistungserbringer;
            },
            kassen() {
                return this.$store.state.currentUser?.mandanten;
            },
        },
        watch: {
            belegNr() {
                return this.belegNr === null ? '' : this.belegNr;
            },
            currentLE(newValue) {
                if (newValue)
                    this.reloadEinspruchListe();
            },
            '$store.state.BMonate'() {
                this.loadBMonat();
            },
        },
        created() {
            eventBus.$on("EinspruchListeReload", this.reloadEinspruchListe);
        },
        activated() {
            //Aufruf der Einsprüche für einen Beleg von dem Beanstandungen-View aus
            if (this.$route.params.belegNr) {
                this.belegNr = this.$route.params.belegNr;
                this.getBelegEinpspruchListe();
            }
        },
        mounted() {
            this.initialize();

            //Wenn der Aufruf der Einspruchsliste nicht über den Beanstandungen-View erfolgt
            if (!this.$route.params.belegNr) {
                this.belegNr = '';
                this.getEinspruchListe();
            }
        },

        methods: {
            customSort: function (items, index, isDesc) {
                items.sort((a, b) => {
                    // Bei 'BetragSumme'/'BetragAnerkannt' muss der Wert zum Sortieren in eine Zahl konvertiert werden
                    if (index[0] == 'betragSumme' || index[0] == 'betragAnerkannt') {
                        if (!isDesc[0]) {
                            return Number(b[index].replace(/[,.]/g, '')) - Number(a[index].replace(/[,.]/g, ''));
                        } else {
                            return Number(a[index].replace(/[,.]/g, '')) - Number(b[index].replace(/[,.]/g, ''));
                        }
                    }
                    //EinpruchNr muss als object extra sortiert werden
                    else if (index[0] == 'einspruch.einspruchNr') {
                        if (!isDesc[0]) {
                            return ((a['einspruch'].einspruchNr == b['einspruch'].einspruchNr) ? 0 : ((a['einspruch'].einspruchNr > b['einspruch'].einspruchNr) ? 1 : -1));
                        }
                        else {
                            return ((a['einspruch'].einspruchNr == b['einspruch'].einspruchNr) ? 0 : ((a['einspruch'].einspruchNr > b['einspruch'].einspruchNr) ? -1 : 1));
                        }
                    }
                    //Entscheidungsdatum
                    else if (index[0] == 'entscheidung') {
                        if (!isDesc[0]) {
                            return ((a['entscheidung'].Datum == b['entscheidung'].Datum) ? 0 : ((a['entscheidung'].Datum > b['entscheidung'].Datum) ? 1 : -1));
                        }
                        else {
                            return ((a['entscheidung'].Datum == b['entscheidung'].Datum) ? 0 : ((a['entscheidung'].Datum > b['entscheidung'].Datum) ? -1 : 1));
                        }
                    }
                    //Dokumentenanzahl --> Nur Dokumente des Einspruchs (anzahlDocE) werden sortiert. Die Dokumente in den Positionen (anzahlDocEPos) werden nicht einbezogen
                    else if (index[0] == 'download') {
                        if (!isDesc[0]) {
                            return ((a['anzahlDoc'].anzahlDocE == b['anzahlDoc'].anzahlDocE) ? 0 : ((a['anzahlDoc'].anzahlDocE > b['anzahlDoc'].anzahlDocE) ? 1 : -1));
                        }
                        else {
                            return ((a['anzahlDoc'].anzahlDocE == b['anzahlDoc'].anzahlDocE) ? 0 : ((a['anzahlDoc'].anzahlDocE > b['anzahlDoc'].anzahlDocE) ? -1 : 1));
                        }
 
                    }
                    // alle anderen Felder werden alphabetisch sortiert
                    else {
                        if (!isDesc[0]) {
                            return ((a[index] == b[index]) ? 0 : ((a[index] > b[index]) ? 1 : -1));
                        }
                        else {
                            return ((a[index] == b[index]) ? 0 : ((a[index] > b[index]) ? -1 : 1));
                        }
                    }
                });
                return items;
            },

            initialize() {
                this.dokumente = null;
                this.einsprueche = [];
                this.selectedEinspruch = null;
                this.selectedRow = null;
                this.expanded = [];

                //this.kassen = this.$store.state.defCache.mandant;
                this.status = this.$store.state.defCache.status;

                //Monate-Liste laden
                this.loadBMonat();

                this.selectedStatus = null;
                this.selectedKasse = null;
                this.selectedMonat = [0];
            },

            loadBMonat() {
                this.bMonate = [];
                if (this.$store.state.BMonate) {
                    this.bMonate = this.$store.state.BMonate.map(e => ({
                        BMonat: e.monat,
                        Bezeichnung: String(e.monat).substring(2, String(e.monat).length) + "/20" + String(e.monat).substring(0, String(e.monat).length - 2)
                    }));
                }

                this.bMonate.unshift({
                    BMonat: null,
                    Bezeichnung: "Alle Monate"
                });
            },
            reloadEinspruchListe() {
                this.initialize();
                //this.selectedStatus = [0];
                this.getEinspruchListe();
            },

            downloadfile(item, art) {
                var url = 'einspruch/downloadfile/';
                var dateiName = "";
                if (art === 1) {
                    dateiName = item.einspruch.einspruchNr + "_Entscheidung.pdf"
                    url += item.einspruch.einspruchID + "/" + art;
                }
                else {
                    dateiName = item.originalName;
                    url += this.selectedEinspruch.aspEinspruchID + "/" + art + "/" + item.dateiName;
                 //   console.log("url", url);
                }
                axios({
                    method: 'get',
                    url: url,
                    responseType: 'arraybuffer',
                })
                    .then((response) => {
                        if (response.status === 200) {
                            const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/octet-stream' }));
                            const link = document.createElement('a');
                            link.href = url;
                            link.setAttribute('download', dateiName);
                            document.body.appendChild(link);
                            link.click();
                        }
                    })
                    .catch(error => console.log('Fehler beim Downloaden: ', error.response));
            },

            getDokumentListe(item) {
                this.isBusy = true;
                this.selectedEinspruch = item.einspruch;
                eventBus.$emit('resetLogoutTimer', {});
                this.dokumente = [];
                this.$http.get('/einspruch/doc', {
                    params: {
                        ASP_ID: this.selectedEinspruch.aspEinspruchID
                    }
                }).then((response) => {
                    this.isBusy = false;
                    if (response.status === 200) {
                        if (response.data !== null) this.dokumente = response.data;
                        this.isBusy = false;
                    }
                }).catch(error => {
                    if (error.response) {
                        this.snackbar = true;
                        this.isBusy = false;
                    }
                });
            },

            async getBelegEinpspruchListe() {
                if (this.isBusy) return;
                this.selectedRow = null;
                this.expanded = [];

                await this.getEinspruchListe()

                if (this.belegNr !== null && this.belegNr !== "") {
                    //warten bis die Einspruchtabelle aufgebaut ist, dann die Details aufrufen
                    this.$nextTick(() => {
                        // The whole view is rendered, so I can safely access or query
                        // the DOM. ¯\_(ツ)_/¯
                        this.selectedRow = this.indexedItems[0];
                        this.expanded.push(this.selectedRow);
                    });
                }
            },

            async getEinspruchListe() {
                this.isBusy = true;
                eventBus.$emit('resetLogoutTimer', {});
                this.einsprueche = [];
                this.selectedEinspruch = null;
                this.selectedRow = null;
                this.expanded = [];
                var vStatus = "";
                if (this.selectedStatus !== null && this.selectedStatus.length > 0) {
                    this.selectedStatus.forEach(e => {
                        vStatus += String(e) + "|";
                    });
                    vStatus = vStatus.slice(0, -1); // letztes "|" entfernen
                }
                else
                    vStatus = this.selectedStatus;

                var vKasse = "";
                if (this.selectedKasse !== null && this.selectedKasse.length > 0) 
                    vKasse = this.selectedKasse?.join('|')
                else 
                    vKasse = this.kassen.map(e => e.mandant).join('|');
                    
                const le = this.$store.state.currentUser.leistungserbringer;
                await this.$http.get('/einspruch', {
                    params: {
                        Leistungserbringer: le,
                        Mandant: vKasse,
                        Monat: this.selectedMonat,
                        Status: vStatus,
                        BelegNr: this.belegNr
                    }
                }).then((response) => {
                    this.isBusy = false;
                    if (response.status === 200) {
                        if (response.data !== null) {
                            let vm = this;
                            response.data.forEach(e => {
                                this.einsprueche.push({
                                    einspruch: {
                                        einspruchID: typeof e.einspruchID != "undefined" ? e.einspruchID : null ,
                                        aspEinspruchID: e.aspEinspruchID,
                                        einspruchNr: typeof e.einspruchNr != "undefined" ? e.einspruchNr : null,
                                    },
                                    mandantBezeichung: this.kassen.find(x => x.mandant == Number(e.mandant)).kurzbezeichnung, bm: e.bm,
                                    anzBelege: e.anzBelege,
                                    status: e.status,
                                    betragSumme: this.convertToEuro(e.betragSumme),
                                    betragAnerkannt: this.convertToEuro(e.betragAnerkannt),
                                    eingangsdatum: e.eingangsdatum,
                                    entscheidung: {
                                        Datum: typeof e.entscheidung !== "undefined" ? e.entscheidung : '',
                                        Dokument: typeof e.einspruchDoc !== "undefined" && typeof e.einspruchDoc > 0 ? e.einspruchDoc : ''
                                    },
                                    anzahlDoc: {
                                        anzahlDocE: e.anzahlDoc,
                                        anzahlDocEPos: e.anzahlDocExtra,
                                        entscheidungDoc: e.entscheidungDoc
                                    },
                                });
                            });

                            if (this.belegNr != null) {
                                this.einsprueche.sort((a, b) => a.status.toString().localecompare(b.status.toString(), 'en-US', { numeric: "true" }));
                            }
                        }
                        this.isBusy = false;
                    }
                }).catch(error => {
                    if (error.response) {
                        this.snackbar = true;
                        this.isBusy = false;
                    }
                });
            },

            selectAndToggleRow(row) {
                this.selectedRow = row;
                this.expanded = this.expanded.includes(row) ? [] : [row];
            },

            addItem: function (item) {
                if (!this.itemsadded.some(e => e.title === item.title)) {
                    this.itemsadded.push(item);
                }
            },
            removeItem: function (item) {
                var indexOfItem = this.itemsadded.findIndex(i => i.title === item.title);
                if (indexOfItem > -1) {
                    this.itemsadded.splice(indexOfItem, 1);
                }
            },
            statusBezeichnung(status) {
                if (status === 2) return 'geschlossen'
                else if (status === 0) return 'offen'
                else if (status === 1) return 'entschieden'
                else return 'offen'
            },
            getColor(status) {
                if (status === 2) return 'grey lighten-2'
                else if (status === 0) return 'yellow lighten-2'
                else if (status === 1) return 'blue lighten-2'
                else return 'teal lighten-3'
            },
            getColorAnerkannt(summe, anerkannt) {
                let s = Math.abs(parseFloat(summe.replace(",", ".")).toFixed(2));
                let a = parseFloat(anerkannt.replace(",", "."));
                if (a === 0.00) {
                    return 'red--text'
                }
                else if (a < s) {
                    return 'orange--text'
                }
                else {
                    return 'green--text'
                }
            },
            convertToEuro(value) {
                return new Intl.NumberFormat('de-DE', { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(value / 100)
            }
        }
    }
</script>