<template>
    <v-container fluid class="pa-0">
        <v-row class="justify-start">
            <v-col cols="12">

                <v-card borderless class="pr-6">
                    <v-card-title class="text-h5 text-md-h4 flex-nowrap align-start">
                        Neuen Einspruch vorbereiten
                    </v-card-title>
                    <v-card-text class="text-body-1 text--primary">
                        <v-container fluid class="pa-0">
                            <v-row v-if="absetzungenCount === 0">
                                <v-col cols="12" md="10" lg="8" xl="6">
                                    <v-alert border="left" type="warning" class="d-inline-block">
                                        Um einen neuen Einspruch vorzubereiten, wählen Sie bitte zuerst auf der Seite "Beanstandungen" die entsprechenden 
                                        Belegnummern aus, für die Sie Einspruch einlegen möchten und klicken dort auf "Einspruch für markierte Belege einlegen".
                                    </v-alert>
                                </v-col>
                            </v-row>
                            <v-row v-if="absetzungenCount !== 0">
                                <v-col cols="12">
                                    <v-data-table v-model="selectedRetaxen" :headers="absetzungen_headers" :items="absetzungen"
                                                  item-key="absetzung.belegNr" @item-selected="selectAbsetzung"
                                                  :dense="$vuetify.breakpoint.mdAndDown"
                                                  class="font-weight-semibold text-no-wrap text-body-2 text-lg-body-1
                                                    scroll-gradient elevation-2 rounded-0 alternating-row-colors"
                                                  show-select hide-default-footer disable-pagination calculate-widths>
                                        <template v-slot:item.absetzung.belegNr="{ item }">
                                            <span>{{ item.absetzung.belegNr }}</span>
                                            <v-tooltip v-if="item.absetzung.eRezept" bottom>
                                                <template v-slot:activator="{ on }">
                                                    <v-icon v-on="on" size="small" class="ml-2">
                                                        mdi-alpha-e-circle
                                                    </v-icon>
                                                </template>
                                                <span>E-Rezept-ID: {{ item.absetzung.eRezept }}</span>
                                            </v-tooltip>
                                        </template>

                                        <template v-slot:item.absetzung.mandant="{ item }">
                                            {{ getMandantBezeichnung(item.absetzung.mandant) }}
                                        </template>

                                        <template v-slot:item.absetzung.monat="{ item }">
                                            {{ monatPretty(item.absetzung.monat) }}
                                        </template>

                                        <!-- FIXME-RL: Sollte hier nicht ein Kurztext stehen und im Dialog ein ausführlicherer Text? -->
                                        <template v-slot:item.absetzung.grund="{ item }">
                                            <div class="d-flex align-center">
                                                <v-btn @click="showGrundlang(item.absetzung.retaxGrundBeschreibung)"
                                                       :small="$vuetify.breakpoint.mdAndDown" icon>

                                                    <v-icon color="primary">
                                                        mdi-text-box-outline
                                                    </v-icon>
                                                </v-btn>
                                                {{ item.absetzung.retaxGrundBeschreibung }}
                                            </div>
                                        </template>

                                        <template v-slot:item.begruendung="{ item }">
                                            <div class="align-center justify-end justify-sm-start begr-text-cell">
                                                <v-tooltip bottom>
                                                    <template v-slot:activator="{ on }">
                                                        <v-btn v-on="on" @click="openBegruendungDialog(item)"
                                                               :small="$vuetify.breakpoint.mdAndDown" icon>

                                                            <v-icon color="primary">
                                                                mdi-fountain-pen-tip
                                                            </v-icon>
                                                        </v-btn>

                                                        <span v-show="item.begruendung" class="text-truncate">
                                                            {{ item.begruendung }}
                                                        </span>
                                                        <span v-show="!item.begruendung">
                                                            {{ $vuetify.breakpoint.xsOnly ? '(Bitte eingeben)' : '...' }}
                                                        </span>
                                                    </template>
                                                    <span>Zum Ändern der Begründung bitte hier klicken!</span>
                                                </v-tooltip>
                                            </div>
                                        </template>

                                        <template v-slot:item.unterlagen="{ item }">
                                            <v-btn @click="item.unterlagen.length ? openDialogUnterlagen(item) : openUploader($refs.itemUploader, item)"
                                                   :small="$vuetify.breakpoint.mdAndDown" text>

                                                <v-icon color="primary">mdi-paperclip</v-icon>
                                                <span v-if="item.unterlagen.length" class="font-weight-semibold text-body-2 text-lg-body-1">
                                                    ({{ item.unterlagen.length }})
                                                </span>
                                            </v-btn>
                                        </template>

                                        <template v-slot:body.append="{ headers }">
                                            <tr :class="{ 'v-data-table__mobile-table-row': $vuetify.breakpoint.xsOnly }">
                                                <td v-bind:colspan="headers.length" :class="['white', { 'v-data-table__mobile-row': $vuetify.breakpoint.xsOnly }]">
                                                    <v-row class="mx-n2 my-0 pa-1 justify-end align-center flex-row-reverse" dense>
                                                        <v-col cols="auto">
                                                            <v-btn @click="openDialogUnterlagen(null)"
                                                                   :small="$vuetify.breakpoint.mdAndDown" text>

                                                                <v-icon color="primary">mdi-paperclip</v-icon>
                                                                <span v-if="einspruchUnterlagen.length" class="font-weight-semibold text-body-2 text-lg-body-1">
                                                                    ({{ einspruchUnterlagen.length }})
                                                                </span>
                                                            </v-btn>
                                                        </v-col>
                                                        <v-col cols="auto" class="flex-grow-1">
                                                            <v-btn color="primary" @click="openUploader($refs.absetzungUploader)"
                                                                   :disabled="!(selectedRetaxen.length > 0)">

                                                                <v-icon class="mr-1">mdi-paperclip</v-icon>
                                                                Dokumente
                                                            </v-btn>
                                                        </v-col>
                                                        <v-col cols="auto">
                                                            <v-btn color="primary" @click="addBegruendung"
                                                                   :disabled="!(selectedRetaxen.length > 0)">

                                                                <v-icon class="mr-1">mdi-fountain-pen-tip</v-icon>
                                                                Begründungstext
                                                            </v-btn>
                                                        </v-col>
                                                    </v-row>
                                                </td>
                                            </tr>
                                        </template>
                                    </v-data-table>
                                </v-col>
                            </v-row>
                            <v-row v-if="absetzungenCount !== 0" class="align-center justify-end mt-4" dense>
                                <v-col cols="auto" class="flex-grow-1">
                                    <v-alert v-if="deactivateSendBtn && errorMessage==''" type="warning" 
                                             class="my-auto py-3" min-height="52">

                                        Bevor Sie einen Einspruch absenden können, müssen Sie für jeden enthaltenen
                                        Beleg eine <strong>Begründung</strong> erfassen.
                                        Optional können Sie auch Dokumente anfügen.
                                    </v-alert>

                                    <v-alert v-else-if="errorMessage!=''" type="error" 
                                             class="my-auto py-3" min-height="52">

                                        {{errorMessage}}
                                    </v-alert>
                                </v-col>
                                <v-col cols="auto">
                                    <v-btn color="primary" :disabled="deactivateSendBtn" @click="doSend" x-large>
                                        Einspruch Absenden
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                </v-card>

            </v-col>
        </v-row>

        <!-- invisible "global" uploaders -->
        <input type="file"
               accept=".pdf, .jpg, .jpeg, .png"
               class="d-none"
               ref="itemUploader"
               @change="addFileSelectedItem($event)"
               @click="$refs.itemUploader.value=null"
               multiple />

        <input type="file"
               accept=".pdf, .jpg, .jpeg, .png"
               class="d-none"
               ref="absetzungUploader"
               @change="addFileSelectedAbsetzung($event)"
               @click="$refs.absetzungUploader.value=null"
               multiple />

        <!-- Dialog Einspruch erfolgreich angelegt -->
        <v-dialog v-model="showDialogSuccess">
            <v-card>
                <v-card-title class="text-h5 text-md-h4 flex-nowrap align-start">
                    <v-icon color="primary" large left>$vuetify.icons.check</v-icon>
                    Ihr Einspruch ist auf dem Weg!
                </v-card-title>
                <v-card-text class="text-body-1 text--primary">
                    <p class="text-h6 text--primary mb-2">
                        Wie geht es weiter?
                    </p>
                    <p class="text-justify text-hyphenate mb-0">
                        Der Einspruch wurde erfolgreich angelegt. Sobald er von unserem System verarbeitet wurde, werden Sie per E-Mail benachrichtigt.
                        Sie finden Ihren Einspruch auch in der Einspruchsliste (bis zur Verarbeitung durch unser System noch ohne Einspruchsnummer).
                        Sie können jederzeit weitere Einsprüche durch Auswahl der Belege auf der Seite Beanstandungen vorbereiten.
                    </p>
                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <v-btn color="primary" @click="showDialogSuccess = false">
                        Schließen
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Dialog Begründungstext -->
        <v-dialog v-model="showDialogBgText">
            <v-card>
                <v-card-title class="text-h5 text-md-h4 flex-nowrap align-start">
                    <v-icon color="primary" large left>mdi-text-box-outline</v-icon>
                    {{ selectedRetaxen.length > 1 && !selectedItem ? 'Begründungstext für ' + selectedRetaxen.length + ' Belege' :  'Begründungstext' }}
                </v-card-title>
                <v-card-text class="text-body-1 text--primary">
                    <v-textarea v-model="begruendung" placeholder="Bitte geben Sie Ihre Begründung ein!"
                                counter="1000" filled auto-grow clearable>

                        <template v-slot:counter="{ props }">
                            <div class="v-counter theme--light">
                                {{ props.value }} / {{ props.max }} (min. {{ bgMinLength }} Zeichen)
                            </div>
                        </template>
                    </v-textarea>

                </v-card-text>
                <v-card-actions>
                    <v-alert dense type="warning" outlined v-if="bgWarnungText" class="mr-2">
                        {{ bgWarnungText }}
                    </v-alert>
                    <v-spacer />
                    <v-btn color="primary" @click="saveBgdText" :disabled="!canSaveBgdText">
                        Übernehmen
                    </v-btn>
                    <v-btn color="primary" @click="$nextTick(() => { showDialogBgText = false; begruendung = null; selectedItem = null; })">
                        Abbrechen
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
       
        <!-- Dialog Detailbegründung -->
        <v-dialog v-model="showDialogDetails">
            <v-card>
                <v-card-title class="text-h5 text-md-h4 flex-nowrap align-start">
                    <v-icon color="primary" large left>mdi-text-box-outline</v-icon>
                    Detaillierte Begründung
                </v-card-title>
                <v-card-text class="text-body-1 text--primary">
                    {{ grundlang }}
                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <v-btn color="primary" @click="showDialogDetails = false">
                        Schließen
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Dialog Unterlagen -->
        <v-dialog v-model="showDialogUnterlagen">
            <v-card>
                <v-card-title class="text-h5 text-md-h4 flex-nowrap align-start">
                    <v-icon color="primary" large left>mdi-paperclip</v-icon>
                    Angefügte Dokumente
                </v-card-title>
                <v-card-text class="text-body-1 text--primary">
                    <v-list dense>
                        <v-list-item v-for="(item, i) in dialogUnterlagen" :key="i" class="px-2">
                            <v-list-item-content>
                                <v-list-item-title class="text-body-1">
                                    {{ item.filename }}
                                </v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-icon>
                                <v-icon @click="showFile(item.hash)">
                                    $vuetify.icons.fileShow
                                </v-icon>
                            </v-list-item-icon>
                            <v-list-item-icon>
                                <v-icon @click="deleteFile(i)">
                                    $vuetify.icons.delete
                                </v-icon>
                            </v-list-item-icon>
                        </v-list-item>
                    </v-list>
                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <v-btn color="primary" @click="openUploader($refs.itemUploader)">
                        Weitere Dokumente
                    </v-btn>
                    <v-btn color="primary" @click="showDialogUnterlagen = false; selectedAbsetzung = null;">
                        Schließen
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Dialog Captcha fehlgeschlagen -->
        <CaptchaFailDialog :isVisible="showDialogCaptchaFailure" />

        <!-- Dialog Upload-Progress -->
        <v-dialog v-model="showDialogUpload">
            <v-card>
                <v-card-title class="text-h5 text-md-h4 flex-nowrap align-start">
                    <v-icon color="primary" large left>mdi-paperclip</v-icon>
                    Senden ...
                </v-card-title>
                <v-card-text class="text-body-1 text--primary">
                    <v-progress-linear v-model="uploadProgress" />
                </v-card-text>
            </v-card>
        </v-dialog>

        <!-- Dialog Vergrößertes Image -->
        <v-dialog v-model="showDialogImg" fullscreen>
            <v-container fluid class="fill-height pa-0 white">
                <v-img :src="imgSrc" @click="showDialogImg = false" class="fill-height" contain />
            </v-container>
        </v-dialog>
    </v-container>
</template>

<script>
    import CaptchaFailDialog from '@/components/CaptchaFailDialog.vue'
    import MD5 from 'md5.js'
    import eventBus from '@/main'

    export default {
        components: {
            CaptchaFailDialog
        },

        data: () => ({
            showDialogImg: false,
            imgSrc: '',
            items: [],
            begruendung: null,
            unterlagen: [],               // array mit File-objects aus <input> und hashes
            absetzungen: [],              // Zusammenstellung aus selectedAbsetzungen plus user-Daten
            selectedAbsetzung: '',        // aktuell selektierte Absetzung / EinspruchPosition
            showDialogBgText: false,
            showDialogSuccess: false,
            showDialogDetails: false,
            showDialogUnterlagen: false,
            showDialogCaptchaFailure: false,
            showDialogUpload: false,
            uploadRunning: false,
            uploadProgress: 0,
            grundlang: '',
            absetzungen_headers: [
                { text: 'Beleg-Nr', value: 'absetzung.belegNr', align: 'start' },
                { text: 'Kostenträger', value: 'absetzung.mandant', align: 'start' },
                { text: 'Monat', value: 'absetzung.monat', align: 'start' },
                { text: 'Grund', value: 'absetzung.grund', align: 'start' },
                { text: 'Retax-Summe (€)', value: 'absetzung.summe', align: 'end' },
                { text: 'Begründungstext', value: 'begruendung', align: 'start', width: '40%' },
                { text: 'Dokumente', value: 'unterlagen', align: 'end', sortable: false },
            ],
            selectedRetaxen: [],
            selectedItem: null,
            deletedUnterlage: null,
            einspruchUnterlagen: [],
            dialogUnterlagen: [],
            NeueASPEinspruchId: null,
            errorMessage: '',
            bgWarnungText: '',
            bgMinLength: 10,
        }),

        mounted() {
            this.initializeData();
        },

        activated() {

            //Einspruchspositionsliste aus ausgewählten Belege erstellen
            if (this.absetzungen.length > 0 && this.$store.state.selectedAbsetzungen?.length > 0) {
                this.errorMessage = "";
                this.selectedAbsetzung = '';
                this.EinspruchPosDocument = [];

                let absetzungentmp = this.$store.state.selectedAbsetzungen?.map(a => {
                    a.selected = false;
                    return {
                        absetzung: a,
                        begruendung: '',
                        unterlagen: []
                    };
                });

                absetzungentmp.forEach(b => {
                    //die Datensätze in der neuen Liste absetzungentmp durch den alten ersetzen, 
                    //falls sie immer noch in der Einspruchsliste vorhanden sind
                    let index = this.absetzungen.findIndex(x => x.absetzung.belegNr === b.absetzung.belegNr);
                    if (index !== -1) {
                        absetzungentmp.splice(absetzungentmp.indexOf(b), 1, this.absetzungen[index]);
                    }
                });

                // markierte Belege aus der selectedRetaxen entfernen, falls sie nicht mehr in der neuen Einspruchsliste vorhanden sind
                if (this.selectedRetaxen.length > 0) {
                    this.selectedRetaxen = this.selectedRetaxen.filter(a => absetzungentmp.includes(a));
                  }
    
                //Wenn alle Einspruchsposition ersetzt wurden, dann die gemeinsamen Unterlagen entfernen
                if (!absetzungentmp.some(r => this.absetzungen.includes(r))) {
                    this.einspruchUnterlagen = [];
                    this.unterlagen = [];
                }
                this.absetzungen = absetzungentmp;
            }
            else {
                this.initializeData();
                this.absetzungen = this.$store.state.selectedAbsetzungen?.map(a => {
                    a.selected = false;
                    return {
                        absetzung: a,
                        begruendung: '',
                        unterlagen: []
                    };
                });
            }
        },

        computed: {
            filesForSelectedAbsetzung: function () {
                return this.unterlagen.length > 0 ? this.unterlagen.filter(u => this.selectedAbsetzung.includes(u.hash)) : null;
            },

            deactivateSendBtn() {
                return this.absetzungen.find(b => b.begruendung.length < this.bgMinLength) || this.absetzungen.length < 1  ? true : false;
            },

            absetzungenCount() {
                return this.absetzungen.length;
            },

            canSaveBgdText() {
                const txt = this.begruendung?.trim();

                // clear erlauben
                if (!txt || txt.length === 0)
                    return true;

                return txt.length >= this.bgMinLength && txt.length <= 1000; 
            }
        },

        methods: {
            addBegruendung() {
                this.bgWarnungText = "";
                this.showDialogBgText = true;
                this.selectedItem = null
                const altBegruendung = this.selectedRetaxen.find(r => r.begruendung.length > 1);

                //Wenn nur einen Beleg ausgewählt ist, dann wird seinen Begründungstext übenommen
                if (this.selectedRetaxen.length === 1 && this.selectedRetaxen[0].begruendung.length > 0) { 
                    this.begruendung = this.selectedRetaxen[0].begruendung
                }
                //ist mehr als ein Beleg selektiert und hat einer davon einen Begründungstext, dann wird eine Warnung im Dialog angezeigt
                else if (altBegruendung && this.selectedRetaxen.length > 1) {
                    this.bgWarnungText = "In Ihrer Auswahlliste sind Belege mit einem Begründungstext vorhanden. Dieser wird überschrieben, wenn der neue Begründungstext übernommen wird."
                }
                console.log('this.bgWarnungText: ', this.bgWarnungText);
            },

            initializeData() {
                this.selectedRetaxen = [];
                this.begruendung = null;
                this.unterlagen = [];
                this.absetzungen = [];
                this.selectedAbsetzung = '';
                this.EinspruchPosDocument = [];
                this.einspruchUnterlagen = [];
                this.errorMessage = "";
            },

            openUploader(ref, item) {

                if (ref && ref.click) {
                    this.isLoading = true
                    window.addEventListener('focus', () => {
                        this.isLoading = false
                    }, { once: true })

                    ref.click();
                }

                if (item)
                    this.selectedAbsetzung = item;
            },

            selectAbsetzung(a) {
                this.absetzungen.find(x => x.absetzung.belegNr === a.item.absetzung.belegNr).absetzung.selected = a.value;
            },

            openDialogUnterlagen(item) {
                eventBus.$emit('resetLogoutTimer', {});
                if (item !== null) {
                    this.selectedAbsetzung = item;
                    this.dialogUnterlagen = item.unterlagen;
                }
                else
                    this.dialogUnterlagen = this.einspruchUnterlagen;

                this.showDialogUnterlagen = true;
            },

            getFileHash(file, callback) {

                /* Achtung:
                 * Hash über gesamten Content zu bilden kann sehr lange dauern!
                 * Idee, nur einen Ausschnitt zu hashen wurde erstmal wieder verworfen.
                 * Implementation (Algorithmus sowie Inhalt) muss mit Controller übereinstimmen,
                 * sonst ist keine Zuordnung möglich.
                 */
                let hash = new MD5().update(file.name + file.size).digest('hex'); //+ file.lastModified
                callback && callback(hash);
            },

            addFileSelectedAbsetzung(e) {
                const vm = this;
                console.log(this.unterlagen);
                eventBus.$emit('resetLogoutTimer', {})

                Array.from(e.target.files).forEach(file => {
                    this.getFileHash(file, (hash) => {
                        console.log('md5 string:', hash);

                        if (!this.unterlagen.find(u => u.hash === hash)) {
                            this.unterlagen.push({ hash: hash, file: file });
                            console.log('added-file:', hash, 'file:', file)
                        }

                        // Wenn absetzungen gleich selektierte Retax-Datensätze sind, dann sind alle Retaxdatensätze ausgewählt.
                        // Die Unterlagen werden dem Einspruch zugeordnet, sonst den einzelnen Belegen
                        if (this.absetzungen.length !== this.selectedRetaxen.length) {

                            //Die Unterlgen der entsprechenden Absetzung zuordnen
                            this.selectedRetaxen.forEach(r => {
                                var i = this.absetzungen.findIndex(a => a.absetzung.belegNr === r.absetzung.belegNr);
                                if (i != -1) {
                                    var r = vm.absetzungen.unterlagen;

                                    // && f.file = file) ToDo: Die unterlage als Liste von objects aus hash und file
                                    //so kann überprüft werden, ob die gleiche Datei mit dem gleichen Dateinamen schon mal geladen wurde.
                                    if (!this.absetzungen[i].unterlagen.find(f => f.hash === hash))
                                        this.absetzungen[i].unterlagen.push({ hash: hash, filename: file.name });
                                }
                            });
                        }
                        else if (!this.einspruchUnterlagen.find(f => f.hash === hash)) {

                            this.einspruchUnterlagen.push({ hash: hash, filename: file.name });
                        }
                    });
                });

                // clear fileselection, else onchange won't trigger
                e.target.value = null;
            },

            addFileSelectedItem(e) {
                const vm = this;
                console.log(this.unterlagen);
                eventBus.$emit('resetLogoutTimer', {});

                Array.from(e.target.files).forEach(file => {
                    this.getFileHash(file, (hash) => {
                        console.log('md5 string:', hash);

                        if (!this.unterlagen.find(u => u.hash === hash)) {
                            this.unterlagen.push({ hash: hash, file: file });
                            console.log('added-file:', hash, 'file:', file)
                        }

                        if (this.selectedAbsetzung !== null && this.selectedAbsetzung !== "") {
                            var i = this.absetzungen.findIndex(a => a.absetzung.belegNr === this.selectedAbsetzung.absetzung.belegNr);
                            //Die Unterlagen der entsprechenden Absetzung zuordnen
                            if (i != -1) {
                                var r = vm.absetzungen.unterlagen;

                                // && f.file = file) ToDo: Die unterlage als Liste von objects aus hash und file
                                //so kann überprüft werden, ob die gleiche Datei mit dem gleichen Dateinamen schon mal geladen wurde.
                                if (!this.absetzungen[i].unterlagen.find(f => f.hash === hash))
                                    this.absetzungen[i].unterlagen.push({ hash: hash, filename: file.name });
                            }
                        }
                        else if (!this.einspruchUnterlagen.find(f => f.hash === hash)) {

                            this.einspruchUnterlagen.push({ hash: hash, filename: file.name });
                        }
                    });

                });

                // clear fileselection, else onchange won't trigger
                e.target.value = null;
            },

            async doSend() {
                this.errorMessage = "";
                const vm = this;
                this.NeueASPEinspruchId = null;

                try {
                    // get captcha token from Google
                    await this.$recaptchaLoaded();
                    const token = await this.$recaptcha('addeinspruch');

                    // verify by API
                    const rsp = await this.$http.get("auth/verifycaptcha", { params: { token: token } });
                    if (rsp.status !== 200)
                        throw new Error('Ungültiges Captcha');
                }
                catch {
                    this.showDialogCaptchaFailure = true;
                    return;
                }

                let EispuchId = null;

                this.showDialogUpload = true;
                eventBus.$emit('resetLogoutTimer');

                var r = vm.absetzungen;
                // Die Einspruchsunterlagen werden in der Datenbank pro Beleg gespeichert
                // hier in der Applikation wird eine Kopie der Absetzungen erstellt und die Unterlagen-Dateien auf die Belege verteilt
                // und diese schliesslich zum Senden verwendet. Damit bei gescheiterter Sendung
                // die Unterlagen in der Applikation nicht pro Beleg erscheinen
                let sendAbsetzungen = JSON.parse(JSON.stringify(this.absetzungen));   //[...this.absetzungen];//Object.assign({}, this.absetzungen);

                sendAbsetzungen.forEach(a => {
                    this.einspruchUnterlagen.forEach(e => { a.unterlagen.push(e) })
                });

                var t = vm.absetzungen;
                let benutzer = this.$store.state.currentUser.benutzer;
                let le = this.$store.state.currentUser.leistungserbringer;

                let data = {
                    Benutzer: benutzer,
                    Leistungserbringer: le,
                    Positionen: sendAbsetzungen.map(a => {
                        return {
                            BelegNr: a.absetzung.belegNr,
                            BuchungID: a.absetzung.buchungID,
                            Begruendung: a.begruendung,
                            Unterlagen: a.unterlagen.map(u => { return u.hash })
                        };
                    })
                };

                var r = vm.absetzungen;
                //console.log('r: ', r);
                let formdata = new FormData();
                formdata.append('data', JSON.stringify(data));

                //console.log(formdata);

                for (let i = 0; i < this.unterlagen.length; i++)
                    formdata.append('files[' + i + ']', this.unterlagen[i].file);

                let uploadConfig = {
                    headers: { 'Content-Type': 'multipart/form-data' },
                    onUploadProgress: (p) => {
                        this.uploadProgress = Math.round((p.loaded * 100) / p.total);
                    }
                };

                //console.log('formdata: ', formdata);
                //const uploadRsp = await this.$http.post('/einspruch', formdata, uploadConfig);
                await this.$http.post('/einspruch', formdata, uploadConfig)
                    .then(response => {
                        console.log(response)
                    })
                    .catch(error => {
                        if (typeof error.response.data.message != "undefined") {
                            this.errorMessage = error.response.data.message
                            //console.log(error.response.data.message)
                        }
                        else if (typeof error.response.data.error != "undefined") {
                            this.errorMessage = error.response.data.error
                            //console.log(error.response.data.error)
                        }

                    });

                if (this.errorMessage !== "") {
                    this.errorMessage += "\nBitte passen Sie Ihre Daten an!";
                    this.showDialogUpload = false;
                    return

                }
                this.initializeData();
                this.$store.commit('updateSelectedAbsetzungen', []);

                await eventBus.$emit('EinspruchErstellt', {});
                await eventBus.$emit('EinspruchListeReload', {});
                this.showDialogUpload = false;
                this.showDialogSuccess = true;

            },

            //Die PDF-Datei oder Bild im neuen Fenster bzw neuen Modal-Dialog öffnen
            showFile(hash) {
                var f = this.unterlagen.find(u => u.hash === hash);
                this.previewFile(f.file);
            },

            deleteFile(index) {

                //Fehlermeldungen zurücksetzen, damit der Anwender weiterarbeiter kann
                this.errorMessage = "";
                //hash merken
                var hash = this.dialogUnterlagen[index].hash;

                //aktuelle Referenz löschen
                this.dialogUnterlagen.splice(index, 1);

                //verwaiste Einträge aus unterlagen löschen
                var noRefsLeft = this.absetzungen.filter(a => a.unterlagen.find(u => u.hash === hash)).length === 0;
                if (noRefsLeft) {
                    let idx = this.unterlagen.findIndex(u => u.hash === hash);
                    this.unterlagen.splice(idx, 1);
                }
            },

            previewFile(file) {
                // Make sure `file.name` matches our extensions criteria
                if (/\.(jpe?g|png|gif|pdf)$/i.test(file.name)) {
                    var reader = new FileReader();
                    reader.readAsDataURL(file);
                    var newBlob = null;
                    if (/\.(pdf)$/i.test(file.name)) {
                        newBlob = new Blob([file], { type: "application/pdf" });
                        var url = window.URL.createObjectURL(newBlob);
                        window.open(url, '_blank');
                    }
                    else if (/\.(jpe?g|png|gif)$/i.test(file.name)) {

                        let vm = this;

                        reader.addEventListener("load", function () {
                            var image = new Image();
                            image.height = 100;
                            image.title = file.name;
                            image.src = this.result;
                            vm.imgSrc = image.src;
                        }, false);

                        vm.showDialogImg = true;
                    }
                    else {
                        console.log('File is not a pdf.', file.type, file);
                        return;
                    }
                }
            },

            showGrundlang(t) {
                this.grundlang = t;
                this.showDialogDetails = true;
            },

            saveBgdText() {
                eventBus.$emit('resetLogoutTimer', {})
                const txt = this.begruendung?.trim() ?? '';

                // nur einen Begründungstext editieren
                if (this.selectedItem !== null) {
                    this.selectedItem.begruendung = txt;
                    this.selectedItem = null;
                }
                // Begründungstext für alle selektierten Belege editieren
                else {
                    this.selectedRetaxen.forEach(r => {
                        var i = this.absetzungen.findIndex(a => a.absetzung.belegNr === r.absetzung.belegNr);
                        if (i != -1) {
                            this.absetzungen[i].begruendung = txt;
                        }
                    });
                }
                this.showDialogBgText = false;
                this.begruendung = null;
            },

            monatPretty(m) {
                return String(m).substring(2, String(m).length) + "/20" + String(m).substring(0, String(m).length - 2);
            },

            startUpload() {
                this.uploadRunning = true;
                setTimeout(() => (this.uploadRunning = false), 5000);
            },

            getMandantBezeichnung(mandant) {
                let mandantList = this.$store.state.defCache?.mandant;
                return mandantList?.find(e => e.Mandant == mandant)?.Kurzbezeichnung;
            },

            openBegruendungDialog(item) {
                this.selectedItem = item;
                this.begruendung = item.begruendung;
                this.bgWarnungText = '';
                this.showDialogBgText = true;
            },
         }
    };
</script>
<style scoped>

    /*#region Layout Begründungstext */
    /* 
        Inhalt soll mobile rechts-, sonst linksbünding ausgerichtet werden, so viel Platz beanspruchen wie zur Verfügung steht,
        dabei aber kein seitliches Scrollen der Tabelle verursachen, sondern stattdessen überlangen Text kürzen.
        Don't touch - it just works. 
    */
    >>> .begr-text-cell {
        display: grid;
        grid-template-columns: auto auto;
    }
    /*#endregion*/

    /*#region Bei mobile-Ansicht select-Checkbox in erster Zeile links ausrichten */
    >>> .v-data-table--mobile .v-data-table__mobile-row:first-child {
        flex-direction: row-reverse;
    }
    /*#endregion*/

</style>