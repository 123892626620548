import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticClass:"grey lighten-5",attrs:{"borderless":"","tile":""}},[_c(VCardTitle,{staticClass:"primary--text text-h5 text-md-h4 flex-nowrap align-start"},[_c(VRow,{staticClass:"align-center"},[_c(VCol,{attrs:{"cols":"auto"}},[_vm._v(" "+_vm._s(_vm.parentSelectedRow.anzBelege)+" "+_vm._s(_vm.parentSelectedRow.anzBelege === 1 ? 'Position' : 'Positionen')+" im Einspruch "+_vm._s(_vm.parentSelectedRow.einspruch.einspruchNr)+" ")]),_c(VCol,{staticClass:"flex-grow-1",attrs:{"cols":"auto"}},[_c(VAlert,{staticClass:"mb-0",attrs:{"value":!_vm.parentSelectedRow.einspruch.einspruchNr,"type":"warning","border":"left","dense":"","outlined":""},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c(VIcon,{staticClass:"mr-2",attrs:{"color":"warning"}},[_vm._v("$vuetify.icons.warning")])]},proxy:true}])},[_vm._v(" Dieser Einspruch wurde noch nicht verarbeitet und hat noch keine Einspruchsnummer ")])],1)],1)],1),_c(VCardText,{staticClass:"text-body-1 text--primary"},[_c(VDataTable,{staticClass:"font-weight-semibold text-no-wrap text-body-2 text-lg-body-1 scroll-gradient elevation-2 rounded-0 alternating-row-colors",attrs:{"headers":_vm.headers,"items":_vm.einspruchPos,"item-key":"belegnr","expanded":_vm.expanded,"dense":_vm.$vuetify.breakpoint.mdAndDown,"items-per-page":_vm.itemsPerPage,"hide-default-footer":_vm.einspruchPos.length <= _vm.itemsPerPage,"single-expand":""},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.belegnr",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.belegnr))]),(item.eRezept)?_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VIcon,_vm._g({staticClass:"ml-2",attrs:{"size":"small"}},on),[_vm._v(" mdi-alpha-e-circle ")])]}}],null,true)},[_c('span',[_vm._v("E-Rezept-ID: "+_vm._s(item.eRezept))])]):_vm._e()]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c(VChip,{attrs:{"color":_vm.getColor(item.status),"small":_vm.$vuetify.breakpoint.mdAndDown}},[_vm._v(" "+_vm._s(item.status)+" ")])]}},{key:"item.anerkannt",fn:function(ref){
var item = ref.item;
return [_c('span',{class:_vm.getColorAnerkannt(item.betrag, item.anerkannt)},[_vm._v(" "+_vm._s(item.anerkannt)+" ")])]}},{key:"item.image",fn:function(ref){
var item = ref.item;
return [_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c(VBtn,_vm._g({attrs:{"small":_vm.$vuetify.breakpoint.mdAndDown,"icon":""},on:{"click":function($event){return _vm.selectAndToggleRow(item)}}},Object.assign({}, tooltip)),[_c(VIcon,{attrs:{"color":"primary"}},[_vm._v(" $vuetify.icons.formatList ")])],1)]}}],null,true)},[_c('span',[_vm._v("Bitte anklicken, um das Rezept-Image zu sichten")])])]}},{key:"item.download",fn:function(ref){
var item = ref.item;
return [(item.begruendungen.anzahlDoc>0)?_c('div',[_c(VMenu,{attrs:{"offset-y":"","bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
var attrs = ref.attrs;
return [_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"small":_vm.$vuetify.breakpoint.mdAndDown,"text":""},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.getDokumentListe(item)}}},'v-btn',attrs,false),Object.assign({}, tooltip, menu)),[_c(VIcon,{attrs:{"color":"primary","small":_vm.$vuetify.breakpoint.mdAndDown}},[_vm._v(" $vuetify.icons.fileDownload ")]),_c('span',{staticClass:"font-weight-semibold text-body-2 text-lg-body-1"},[_vm._v(" ("+_vm._s(item.begruendungen.anzahlDoc)+") ")])],1)]}}],null,true)},[(item.begruendungen.anzahlDoc==1)?_c('span',[_vm._v("Bitte anklicken, um das Dokument zu sichten")]):(item.begruendungen.anzahlDoc > 1)?_c('span',[_vm._v("Bitte anklicken, um die Dokumente zu sichten")]):_vm._e()])]}}],null,true)},[_c(VList,{attrs:{"dense":""}},_vm._l((_vm.dokumente),function(item,index){return _c(VListItem,{key:index,on:{"click":function($event){return _vm.downloadfile(item)}}},[_c(VListItemAction,[_c(VListItemTitle,{staticClass:"text-body-1"},[_vm._v(" "+_vm._s(item.originalName)+" ")])],1)],1)}),1)],1)],1):_vm._e()]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"pa-0",attrs:{"colspan":headers.length}},[_c(VContainer,{staticClass:"pa-4",attrs:{"fluid":""}},[_c(VRow,{staticClass:"align-center",attrs:{"dense":""}},[_c(VCol,{attrs:{"cols":"12","sm":"6","lg":"5"}},[_c('img',{staticClass:"tableImg",attrs:{"src":'data:image/png;base64,'+ item.image}})]),_c(VCol,{attrs:{"cols":"12","sm":"6","lg":"7"}},[_c(VCard,{attrs:{"borderless":""}},[_c(VCardTitle,{staticClass:"text-h5"},[_vm._v(" Retax-Begründung ")]),_c(VCardText,{staticClass:"text-body-1 text--primary font-weight-semibold"},[_vm._v(" "+_vm._s(item.begruendungen.grundlang)+" ")])],1),_c(VCard,{directives:[{name:"show",rawName:"v-show",value:(item.begruendungen.einspruchBegruendung),expression:"item.begruendungen.einspruchBegruendung"}],attrs:{"borderless":""}},[_c(VCardTitle,{staticClass:"text-h5"},[_vm._v(" Einspruchsbegründung ")]),_c(VCardText,{staticClass:"text-body-1 text--primary font-weight-semibold"},[_vm._v(" "+_vm._s(item.begruendungen.einspruchBegruendung)+" ")])],1)],1)],1)],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }